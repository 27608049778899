.input {
  @apply py-3 px-3.5 rounded-lg border bg-background200 text-textBase text-lg;
}

.menu {
  &__popup {
    @apply mt-2 right-0 absolute p-2 border border-grey500 outline-none rounded-lg bg-background flex flex-col items-stretch;
  }

  &__item {
    @apply flex flex-col items-start py-2 px-4 cursor-pointer rounded-md transition-all select-none;

    &.disabled {
      @apply opacity-50 cursor-not-allowed;
    }

    &--selected {
      @extend .menu__item;
      @apply bg-background100;
    }
  }
}

.label {
  @apply text-grey100 text-sm w-max ml-2;
}

.icon-label-row {
  @apply flex items-center;
}

.dropdowninput {
  @apply py-2 px-3.5 mb-4 rounded-lg border border-borderColor bg-background500 text-textBase font-semibold;
}
