.wrapper {
  @apply relative flex flex-col;
}

.background {
  @apply absolute inset-0 rounded-lg border border-borderColor bg-background200;

  &.disabled {
    background-color: transparent;
  }
}

.labelTooltipContainer {
  @apply absolute flex items-center gap-2 font-semibold;
  top: 0.5rem;
  left: 0.5rem;

  &.disabled {
    background-color: transparent;
    @apply text-textLabel;
  }
}

.insideLabel {
  @apply bg-background200 px-1;

  &.disabled {
    background-color: transparent;
  }
}

.input {
  @apply relative py-3 px-3.5 text-textBase text-sm;
  z-index: 9;
  background-color: transparent;
  border-top: transparent;
  margin-top: 1.8rem;

  &:focus {
    border-color: currentColor;
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
  }
}
