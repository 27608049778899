.wrapper {
  @apply relative flex flex-col items-center border border-borderColor rounded-lg p-2;
}

.titleContainer {
  @apply flex items-center mb-4;
}

.title {
  @apply text-textLabel font-semibold mr-1;
}

.controls {
  @apply flex items-center justify-center w-full mb-4;
}

.button {
  @apply bg-background300 px-2 py-2 rounded-lg text-textLabel;
}

.value {
  @apply mx-1 text-textBase bg-background300 w-20;
}

.label {
  @apply text-textLabel;
}
