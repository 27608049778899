.container {
  @apply p-6 bg-white rounded-lg shadow-lg;
}

.title {
  @apply text-lg font-semibold;
}

.header-container {
  @apply flex items-center mb-2;
}

.search-container {
  @apply relative flex items-center ml-4 mt-4 w-1/4;
}

.search-bar {
  @apply pl-10 pr-4 py-2 w-full text-sm bg-black  border border-grey750 rounded-lg;
}

.search-icon {
  @apply absolute left-3;
}

.table {
  @apply border border-solid border-grey750 rounded-2xl px-8 py-6 w-full;
}

.auto-table {
  @apply table-auto w-full block;
}

.thead {
  @apply w-full block;
}

.tr {
  @apply text-xs flex w-full border-b border-grey750;

  &:nth-child(odd) {
    @apply bg-grey900;
  }
}

.td,
.th {
  @apply text-center font-medium py-5 w-full;
}

.th {
  @apply bg-black text-grey150 border-t border-grey750;
}

.left-text {
  @apply text-left pl-4 pr-72;
}

.tbody {
  @apply text-sm font-medium overflow-y-auto block;
}

.in {
  @apply text-primaryGreen;
}

.out {
  @apply text-yellow;
}

.txn-cell {
  @apply flex items-center text-left ml-4 mr-56;
}

.no-border-top {
  border-top: none !important;
  padding-top: 0 !important;
}

.left-align {
  text-align: left !important;
  margin-top: 30px;
}
